// Import necessary React functions, components, and types
import React, { useRef, useEffect, useState } from 'react'
import { Message, DocumentsState } from '../types'
import autosize from 'autosize' // Utility for automatic textarea resizing
import MessageComponent from './Message'

// Define the props expected by the MessagesContainer component
interface MessagesContainerProps {
    messages: Message[]
    onEditMessage: (messageId: number, newMessage: string) => void
    setEditingMessageId: React.Dispatch<React.SetStateAction<number | null>>
    editingMessageId: number | null
    onSwitchEdit: (id: number, back: boolean) => void
    waitingForResponse: boolean
    documents: DocumentsState
    setDocuments: React.Dispatch<React.SetStateAction<DocumentsState>>
}

const MessagesContainer: React.FC<MessagesContainerProps> = ({
    messages,
    onEditMessage,
    setEditingMessageId,
    editingMessageId,
    onSwitchEdit,
    waitingForResponse,
    documents,
    setDocuments,
}) => {
    // Refs for handling UI effects in the message list and inputs
    const messagesEndRef = useRef<HTMLDivElement>(null) // For auto-scrolling to the latest message
    const editingInputRef = useRef<HTMLTextAreaElement>(null) // Ref to the textarea being edited
    const spanRef = useRef<HTMLDivElement>(null) // Ref to a span for measuring width for resizing
    const [editingName, setEditingName] = useState('') // State to hold the value of the editing input

    // Effect for auto-scrolling to the bottom of the messages list when messages update
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [messages])

    // Effect to focus the input field when editing starts
    useEffect(() => {
        if (editingMessageId) {
            editingInputRef.current?.focus()
            // Set cursor position to the end of the text
            editingInputRef.current?.setSelectionRange(
                editingInputRef.current?.value.length,
                editingInputRef.current?.value.length
            )
        }
    }, [editingMessageId])

    // Effect to manage dynamic resizing of the input based on its content
    useEffect(() => {
        if (editingInputRef.current && spanRef.current) {
            const mirrorWidth = spanRef.current.offsetWidth // Measure the mirror span width
            if (mirrorWidth < 512 && editingName.indexOf('\n') === -1) {
                editingInputRef.current.style.width = `${mirrorWidth - 31}px` // Adjust width for single-line editing
            } else {
                editingInputRef.current.style.width = `${32}rem` // Default to a fixed width on multi-line or wider content
                autosize(editingInputRef.current) // Apply autosize to adjust height dynamically
            }
        }
    }, [editingName, editingMessageId])

    return (
        <div className='flex-1 overflow-y-auto flex flex-col pb-2 pr-2 mx-4'>
            {/* This empty div is used to push all chat messages upwards, ensuring the latest is visible */}
            <div className='flex-grow'></div>
            {/* Render each message using the MessageComponent */}
            {messages.map((message) => (
                <MessageComponent
                    key={message.id} // Unique key for React list rendering
                    message={message}
                    editingInputRef={editingInputRef}
                    editingMessageId={editingMessageId}
                    onEditMessage={onEditMessage}
                    setEditingMessageId={setEditingMessageId}
                    onSwitchEdit={onSwitchEdit}
                    waitingForResponse={waitingForResponse}
                    setEditingName={setEditingName}
                    editingName={editingName}
                    spanRef={spanRef}
                    documents={documents}
                    setDocuments={setDocuments}
                />
            ))}
            {/* Ref div for scrolling to the bottom of the message list */}
            <div ref={messagesEndRef} />
        </div>
    )
}

export default MessagesContainer
