// Import necessary React components and types
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../context/UserContext'

// Define the props expected by the MenuBar component
interface MenuBarProps {
}

const MenuBar: React.FC<MenuBarProps> = (
) => {
    const [isMenuOpen, setMenuOpen] = useState(false)
    
    const {user} = useUser()

    const handleToggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    }

    const { logoutUser } = useUser();
    const navigate = useNavigate();
    const handleLogoutUser = () => {
        logoutUser();
        navigate('/login')
    }
    return (
        <div className="absolute top-4 right-4">
            <button
                className="flex items-center space-x-2 bg-gray-200 rounded-full px-4 py-2 shadow hover:bg-gray-300"
                onClick={handleToggleMenu}
            >
                {/* <img
                src="https://via.placeholder.com/40"
                alt="User Avatar"
                className="w-8 h-8 rounded-full"
                /> */}
                <span className="text-gray-700 font-medium">{ user?.email || "Jane Doe"}</span>
            </button>

            {/* Context Menu */}
            {isMenuOpen && (
                <div className="absolute left-1/2 mt-2 w-48 bg-white border rounded-lg shadow-lg transform -translate-x-1/2">
                <ul className="py-2">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Profile</li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Settings</li>
                    <li onClick={handleLogoutUser} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Logout</li>
                </ul>
                </div>
            )}
        </div>
    )
}

export default MenuBar