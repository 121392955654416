// Import necessary React components and hooks
import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Conversation, ConversationsState, User} from '../types'
import { useUser } from '../context/UserContext'

// Define the props expected by the Sidebar component
interface SidebarProps {
    conversations: ConversationsState
    onConversationSelect: (id: number) => void
    selectedConversation: Conversation | null
    onCreateNewConversation: () => void
    onRenameConversation: (id: number, newName: string) => void
    onDeleteConversation: (id: number) => void
    editingConversationId: number | null
    setEditingConversationId: React.Dispatch<
        React.SetStateAction<number | null>
    >
}

const Sidebar: React.FC<SidebarProps> = ({
    conversations,
    onConversationSelect,
    selectedConversation,
    onCreateNewConversation,
    onRenameConversation,
    onDeleteConversation,
    editingConversationId,
    setEditingConversationId,
}) => {
    // State to manage the input value for editing conversation names
    const [editingName, setEditingName] = useState('')
    const [collapsed, setCollapsed] = useState(false);
    const {logoutUser} = useUser();
    const editingInputRef = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()

    // Effect to automatically focus the input when editing begins
    useEffect(() => {
        if (editingConversationId) {
            editingInputRef.current?.focus()
        }
    }, [editingConversationId])

    // Handles initiating the edit of a conversation name
    const handleEditConversationName = (conversation: {
        id: number
        name: string
    }) => {
        setEditingConversationId(conversation.id)
        setEditingName(conversation.name)
    }

    // Handles canceling the edit operation
    const handleCancelEdit = () => {
        setEditingConversationId(null)
        setEditingName('')
    }

    // Handles saving the new conversation name
    const handleSaveEdit = () => {
        if (editingConversationId !== null) {
            onRenameConversation(editingConversationId, editingName)
            handleCancelEdit()
        }
    }

    return (
        <div
            className={`${
                collapsed ? 'w-24' : 'w-[500px]'
            } bg-slate-800 flex flex-col h-full transition-all duration-300 max-w-none`}
        >
            {/* Header section with logout functionality */}
            
            {/* Button to toggle collapse */}
            <div className='flex justify-center items-center'>
                <button
                    onClick={() => setCollapsed(!collapsed)}
                    className='text-white px-4 py-2 rounded transition duration-200 ease-in-out cursor-pointer my-1 bg-gray-600 hover:bg-gray-700 w-auto'
                >
                    {collapsed ? 'Expand' : 'Collapse'}
                </button>
            </div>

            {/* Button to create a new conversation */}
            {!collapsed && (
                <button
                    onClick={onCreateNewConversation}
                    className='text-left text-white bg-orange-600 px-4 py-3 rounded transition duration-200 ease-in-out cursor-pointer my-1 hover:bg-gray-700 font-semibold shadow w-full'
                >
                    Start New Conversation
                </button>
            )}
            {/* List of conversations */}
            <div className='overflow-y-auto p-4 flex-grow'>
                {conversations.conversations.map((conversation) => (
                    <div>
                        {conversation.name !== 'New Chat' ? (
                            <div
                                key={conversation.id}
                                className={`flex items-center mb-2 rounded transition duration-200 ease-in-out cursor-pointer relative group ${
                                    conversation.id === selectedConversation?.id
                                        ? 'bg-gray-800 shadow font-semibold'
                                        : 'hover:bg-gray-900 font-normal hover:shadow-md'
                                }`}
                            >
                                {editingConversationId === conversation.id ? (
                                    // Input field for editing conversation name
                                    <React.Fragment>
                                        <input
                                            ref={editingInputRef}
                                            type='text'
                                            value={editingName}
                                            onChange={(e) =>
                                                setEditingName(e.target.value)
                                            }
                                            className='flex-grow px-2 py-1 rounded text-gray-200 bg-gray-900 border border-gray-700'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') handleSaveEdit()
                                            }}
                                            onBlur={handleCancelEdit}
                                        />
                                        <button
                                            onClick={handleSaveEdit}
                                            onMouseDown={(e) => e.preventDefault()}
                                            className='text-green-500 hover:text-green-400 mx-1'
                                        >
                                            ✔
                                        </button>
                                        <button
                                            onMouseDown={(e) => e.preventDefault()}
                                            onClick={handleCancelEdit}
                                            className='text-red-500 hover:text-red-400 mx-1'
                                        >
                                            ✖
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    // Displaying the conversation name
                                    <React.Fragment>
                                        <button
                                            onClick={() =>
                                                onConversationSelect(conversation.id)
                                            }
                                            className={`flex-grow flex items-center text-left text-gray-200 px-4 py-2`}
                                        >
                                            <p className='flex-grow'>
                                                {collapsed ? '' : conversation.name}
                                            </p>
                                            <div className='flex items-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-150'>
                                                {!collapsed && (
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation() // Prevents the onConversationSelect from being called
                                                            handleEditConversationName(
                                                                conversation
                                                            )
                                                        }}
                                                        className='text-xs text-gray-400 hover:text-gray-200 mx-1'
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                )}
                                                {!collapsed && conversations.conversations.length > 1 && (
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation() // Prevents the onConversationSelect from being called
                                                            onDeleteConversation(
                                                                conversation.id
                                                            )
                                                        }}
                                                        className='text-xs text-red-600 hover:text-red-400 mx-1'
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </button>
                                    </React.Fragment>
                                )}
                            </div>
                        ) : null }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Sidebar
