import React, { useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import autosize from 'autosize'
import Modal from './Modal'
import { API_BASE_URL, DEFAULT_SYSTEM_PROMPT } from '../constants'
import { useUser } from '../context/UserContext'

interface ChatInputProps {
    currentInput: string
    setCurrentInput: React.Dispatch<React.SetStateAction<string>>
    handleSendMessage: () => void
    waitingForResponse: boolean
    systemPrompt: { id: number; prompt: string }
    setSystemPrompt: React.Dispatch<
        React.SetStateAction<{ id: number; prompt: string }>
    >
}

const ChatInput: React.FC<ChatInputProps> = ({
    currentInput,
    setCurrentInput,
    handleSendMessage,
    waitingForResponse,
    systemPrompt,
    setSystemPrompt,
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [newSystemPrompt, setNewSystemPrompt] = React.useState(
        systemPrompt['prompt']
    )
    const { user } = useUser()

    // update system prompt in text area whenever it changes
    React.useEffect(() => {
        setNewSystemPrompt(systemPrompt['prompt'])
    }, [systemPrompt])

    const [isModalOpen, setIsModalOpen] = React.useState(false)

    const createSystemPrompt = async (prompt: string) => {
        if (!user) { throw new Error('User not logged in') }
        const response = await fetch(`${API_BASE_URL}/system_prompt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken,
            },
            body: JSON.stringify({
                prompt: prompt
            }),
        })
        if (!response.ok) {
            console.error('Failed to create system prompt')
        }
        const json = await response.json()
        setSystemPrompt({ id: json.id, prompt: json.prompt })
    }

    useEffect(() => {
        autosize(textareaRef.current as HTMLTextAreaElement)
    })
    return (
        <div
            className={`mt-auto w-full self-center pt-4 pb-${user?.admin ? '4' : '8'
                }`}
        >
            <div className='flex'>
                <textarea
                    className='flex-grow px-4 py-2 rounded-lg focus:outline-none resize-none max-h-48 mr-4'
                    ref={textareaRef}
                    placeholder='Message ChatDCP...'
                    value={currentInput}
                    onChange={(e) => setCurrentInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault()
                            handleSendMessage()
                            textareaRef.current?.setAttribute('style', '')
                        }
                    }}
                    rows={1}
                />

                <button
                    className={`${waitingForResponse
                        ? 'bg-blue-700 cursor-default'
                        : 'bg-blue-600'
                        }  w-[5%] h-10 rounded-lg hover:bg-blue-700 focus:outline-none text-white self-center`}
                    onClick={() => {
                        if (!waitingForResponse) {
                            textareaRef.current?.setAttribute('style', '')
                            handleSendMessage()
                        }
                    }}
                >
                    <FontAwesomeIcon
                        icon={faArrowUp}
                        className={`text-blue-100`}
                    />
                </button>
            </div>
            {user?.admin && (
                <div className='flex mt-4 justify-center'>
                    <button
                        className='text-white bg-blue-700 rounded-md py-2 px-4 hover:bg-blue-800 transition-all'
                        onClick={() => setIsModalOpen(true)}
                    >
                        Edit System Prompt
                    </button>
                </div>
            )}
            <Modal
                isOpen={isModalOpen}
                onReset={() => {
                    setNewSystemPrompt(DEFAULT_SYSTEM_PROMPT)
                }}

                onClose={() => {
                    console.log(systemPrompt['prompt'])
                    setNewSystemPrompt(systemPrompt['prompt'])
                    setIsModalOpen(false)
                }}
                onConfirm={() => {
                    createSystemPrompt(newSystemPrompt)
                    setIsModalOpen(false)
                }}
                confirmColor='bg-green-500'
                confirmColorHover='hover:bg-green-700'
                resetColor='bg-blue-500'
            >
                <p className='font-bold'>Edit System Prompt</p>
                <textarea
                    className={`w-[50vw] h-[50vh] mt-2 p-2 rounded-lg resize-none `}
                    placeholder='Enter new system prompt here...'
                    value={newSystemPrompt}
                    onChange={(e) => setNewSystemPrompt(e.target.value)}
                ></textarea>
            </Modal>
        </div>
    )
}

export default ChatInput
