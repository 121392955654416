// Import necessary hooks and components from React and other custom components
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import ConversationWindow from '../components/ConversationWindow'
import Modal from '../components/Modal'
import useMessages from '../hooks/useMessages'
import useConversations from '../hooks/useConversations'
import React from 'react'
import { API_BASE_URL, DEFAULT_SYSTEM_PROMPT } from '../constants'
import {
    ConversationsState,
    MessagesState,
    Message,
    Conversation,
    DocumentsState,
} from '../types'
import { useUser } from '../context/UserContext'
import { useAuth0 } from '@auth0/auth0-react'
import MenuBar from './MenuBar'



const Main: React.FC = () => {
    // State management for conversations, messages, and documents
    const [conversations, setConversations] = useState<ConversationsState>({
        conversations: [],
    })
    const [messages, setMessages] = useState<MessagesState>({ messages: [] })
    const [documents, setDocuments] = useState<DocumentsState>({})
    const [selectedConversation, setSelectedConversation] =
        useState<Conversation | null>(null)
    const [systemPrompt, setSystemPrompt] = useState<{
        id: number
        prompt: string
    }>({ id: -1, prompt: DEFAULT_SYSTEM_PROMPT })
    const {isLoading, isAuthenticated} = useAuth0()
    const { user} = useUser()

    
    // Hook to programmatically navigate using React router
    const navigate = useNavigate()

    // Function to fetch messages for a selected conversation
    const fetchConversationMessages = async (conversation: Conversation) => {
        try {
            if (!user || !conversation) {
                return
            }
            console.log('Fetching messages')
            const messagesResponse = await fetch(
                `${API_BASE_URL}/message/conversation_${conversation!.id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${user.accessToken}`,
                    },
                }
            )
            const messagesDataJson = await messagesResponse.json()
            const messagesData: Message[] = messagesDataJson.map(
                (message: any) => ({
                    id: message.id,
                    userID: message.user_id,
                    text: message.text,
                    role: message.role,
                    conversationID: message.conversation_id,
                    createdAt: new Date(message.created_at),
                    nextMessageID: message.next_message_id,
                    previousMessageID: message.previous_message_id,
                    editIndex: message.edit_index,
                    previousEditID: message.previous_edit_id,
                    nextEditID: message.next_edit_id,
                    editAmount: message.edit_amount,
                    sources: message.sources,
                    systemPromptID: message.system_prompt_id,
                })
            )
            // Creating a map of messages by id for easy access
            let messageMap: { [key: string]: Message } = {}
            messagesData.forEach((message: Message) => {
                messageMap[message.id] = message
            })
            // Collecting messages starting from the last viewed message
            let currMessages: Message[] = []
            let currMessage: Message | null =
                messageMap[conversation.lastViewedMessageId]
            currMessages.push(currMessage)
            while (currMessage?.previousMessageID) {
                currMessage = messageMap[currMessage.previousMessageID]
                currMessages.push(currMessage)
            }
            // Fetching system prompt for the last message if user is admin. 0 index is the last message until messages get reversed
            if (user.admin && currMessages[0].systemPromptID) {
                try {
                    const response = await fetch(
                        `${API_BASE_URL}/system_prompt/${currMessages[0].systemPromptID}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${user.accessToken}`,
                            },
                        }
                    )
                    const systemPromptData = await response.json()
                    setSystemPrompt({
                        id: systemPromptData.id,
                        prompt: systemPromptData.prompt,
                    })
                } catch (error) {
                    console.error('Error fetching system prompt: ', error)
                }
            }
            setMessages({ messages: currMessages.reverse() })
        } catch (error) {
            console.error('Error fetching messages: ', error)
        }
    }

    // Effect hook to fetch conversation data when component mounts
    useEffect(() => {
        if (!isLoading && !isAuthenticated){
            console.log("redirecting");
            navigate("/")
        }
        if (!user){
            return
        }
        else{
            console.log(user);
        }
        console.log("FETCHING CONVS", user)
        const fetchData = async () => {
            const conversationsResponse = await fetch(
                `${API_BASE_URL}/conversation/${user.sub}`,
                {
                    headers: {
                        'Authorization': `Bearer ${user.accessToken}`,
                    },
                }
            )
            const conversationsDataJson = await conversationsResponse.json()
            const conversationsData: Conversation[] = conversationsDataJson.map(
                (conversation: any) => ({
                    id: conversation.id,
                    userId: conversation.user_id,
                    name: conversation.name,
                    createdAt: new Date(conversation.created_at),
                    updatedAt: new Date(conversation.updated_at),
                    lastViewedMessageId: conversation.last_viewed_message_id,
                })
            )
            // Sorting conversations by update time
            let newConversations: ConversationsState = {
                conversations: conversationsData,
            }
            newConversations.conversations.sort(
                (a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()
            )
            setConversations(newConversations)
            setSelectedConversation(newConversations.conversations[0])
            fetchConversationMessages(newConversations.conversations[0])
        }
        fetchData()
    }, [user, navigate])
    
    // Custom hooks for handling conversation and message related operations
    const {
        isDeleteModalOpen,
        conversationToDelete,
        editingConversationId,
        setEditingConversationId,
        onCreateNewConversation,
        onRenameConversation,
        onDeleteConversation,
        handleCloseModal,
        handleConfirmDelete,
    } = useConversations(
        conversations,
        setConversations,
        setMessages,
        messages,
        selectedConversation,
        setSelectedConversation,
    )

    const {
        waitingForResponse,
        handleSendMessage,
        onEditMessage,
        setEditingMessageId,
        editingMessageId,
        onSwitchEdit,
    } = useMessages(
        selectedConversation,
        setSelectedConversation,
        conversations,
        messages,
        setMessages,
        setConversations,
        systemPrompt
    )

    // Effect hook to re-fetch messages when the selected conversation changes
    useEffect(() => {
        if (
            selectedConversation &&
            messages.messages.length !== 0 &&
            messages.messages[0].conversationID !== selectedConversation.id
        ) {
            console.log('New conversation selected')
            fetchConversationMessages(selectedConversation)
        }
    }, [selectedConversation, messages.messages])

    // Rendering the main component structure with Sidebar, ConversationWindow, and Modal
    return (
        <div className='flex h-screen bg-slate-900'>
            <Sidebar
                conversations={conversations}
                onConversationSelect={(id: number) => {
                    let newSelectedConversation =
                        conversations.conversations.find(
                            (conversation) => conversation.id === id
                        )
                    if (newSelectedConversation) {
                        setSelectedConversation(newSelectedConversation)
                    }
                }}
                selectedConversation={selectedConversation}
                onCreateNewConversation={onCreateNewConversation}
                onRenameConversation={onRenameConversation}
                onDeleteConversation={onDeleteConversation}
                editingConversationId={editingConversationId}
                setEditingConversationId={setEditingConversationId}
            />
            <div className='flex w-full'>
                <MenuBar/>
                <ConversationWindow
                    selectedConversation={selectedConversation}
                    messages={messages.messages}
                    onSendMessage={handleSendMessage}
                    waitingForResponse={waitingForResponse}
                    onEditMessage={onEditMessage}
                    setEditingMessageId={setEditingMessageId}
                    editingMessageId={editingMessageId}
                    onSwitchEdit={onSwitchEdit}
                    documents={documents}
                    setDocuments={setDocuments}
                    systemPrompt={systemPrompt}
                    setSystemPrompt={setSystemPrompt}
                />
            </div>
            <Modal
                isOpen={isDeleteModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete}
                confirmColor='bg-red-500'
                confirmColorHover='hover:bg-red-700'
            >
                <p>
                    Are you sure you want to delete{' '}
                    {conversationToDelete &&
                        conversations.conversations.find(
                            (c) => c.id === conversationToDelete
                        )!.name}
                    ?
                </p>
            </Modal>
            
        </div>
    )
}

export default Main
