// Import necessary React components and types
import React, { useState } from 'react'
import MessagesContainer from './MessagesContainer'
import ChatInput from './ChatInput'
import { Message, Conversation, DocumentsState, User} from '../types'
import { useUser } from '../context/UserContext'

// Define the props expected by the ConversationWindow component
interface ConversationWindowProps {
    selectedConversation: Conversation | null
    messages: Message[]
    onSendMessage: (message: string) => void
    waitingForResponse: boolean
    onEditMessage: (messageId: number, newMessage: string) => void
    setEditingMessageId: React.Dispatch<React.SetStateAction<number | null>>
    editingMessageId: number | null
    onSwitchEdit: (id: number, back: boolean) => void
    documents: DocumentsState
    setDocuments: React.Dispatch<React.SetStateAction<DocumentsState>>
    systemPrompt: { id: number; prompt: string }
    setSystemPrompt: React.Dispatch<
        React.SetStateAction<{ id: number; prompt: string }>
    >
}

const ConversationWindow: React.FC<ConversationWindowProps> = ({
    selectedConversation,
    messages,
    onSendMessage,
    waitingForResponse,
    onEditMessage,
    setEditingMessageId,
    editingMessageId,
    onSwitchEdit,
    documents,
    setDocuments,
    systemPrompt,
    setSystemPrompt,
}) => {
    // State to manage the input field content for the chat
    const [currentInput, setCurrentInput] = useState('')

    // Function to handle sending a message, ensuring it's not just whitespace
    const handleSendMessage = () => {
        if (!currentInput.trim()) return // Prevent sending empty messages
        onSendMessage(currentInput)
        setCurrentInput('') // Clear input after sending
    }
    const {user} = useUser()

    // Render the ConversationWindow component
    return (
        <div
            className={`w-5/6 pb-4 pt-4 pl-4 col-12 ${
                !user && 'pointer-events-none'
            }`}
        >
            <div className='flex flex-col h-full items-center justify-center max-w-screen-md mx-auto'>
                {/* Messages container that shows all messages in the conversation */}
                <MessagesContainer
                    messages={messages}
                    onEditMessage={onEditMessage}
                    setEditingMessageId={setEditingMessageId}
                    editingMessageId={editingMessageId}
                    onSwitchEdit={onSwitchEdit}
                    waitingForResponse={waitingForResponse}
                    documents={documents}
                    setDocuments={setDocuments}
                />
                {/* Chat input component to type and send messages */}
                {selectedConversation && (
                    <ChatInput
                        currentInput={currentInput}
                        setCurrentInput={setCurrentInput}
                        handleSendMessage={handleSendMessage}
                        waitingForResponse={waitingForResponse}
                        systemPrompt={systemPrompt}
                        setSystemPrompt={setSystemPrompt}
                    />
                )}
            </div>
        </div>
    )
}

export default ConversationWindow
